<script>
// import PageHeader from "@/components/page-header";
// import { required } from "vuelidate/lib/validators";
import { paymentMethods } from "@/state/helpers";
import {
  FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_MERCHANTS_LIST
} from "@/state/actions.type";
// import FilterBtn from '../../../../components/filter-btn.vue';
import Multiselect from "vue-multiselect";
import Common from "../../../../helpers/Common";

export default {
  components: {
    // PageHeader,
    // FilterBtn,
    Multiselect
},

  data() {
    return {
      showFilterSection: false,
      countryForFilter: [],
      isFilterApplied: true,
      merchant: null,
      countries: []
    };
  },

  computed: {
    paymentChannelCredsList() {
      return this.$store.state.paymentChannels.channels;
    },

    userCountry() {
      return this.$store.getters["auth/getCurrentUserCountry"];
    },

    chaiPayKey() {
      return this.$store.getters["auth/iamportKey"];
    },

    // items() {
    //   return [
    //     {
    //       text: this.$t("menuitems.integration.list.payconfig"),
    //       active: true
    //     },
    //     {
    //       text: this.$t("menuitems.integration.list.pay-channels"),
    //       active: true,
    //     },
    //   ];
    // },

    // title() {
    //   return this.$t("menuitems.integration.list.payout-channels");
    //   // return this.$t("menuitems.integration.list.pay-channelstwo");
    // },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  created() {
    this.countryForFilter = [ this.userCountry, 'GLOBAL' ];
    this.countries = Common.countryList();

    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" },
      }).then((response) => {
        if(response.content.length > 0) {
          this.merchant = response.content[0];
          this.fetchPaymentChannels();
        }
      })
    } else {
      this.fetchPaymentChannels();
    }

  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
  },

  validations: {
    paymentChannelCreds: {},
  },

  methods: {
    ...paymentMethods,
    onCopyWebhookURL() {
      this.$notify({
        type: "success",
        text: `PortOne provided webhook for ${this.currentChannel} copied to clipboard.`,
        closeOnClick: true,
      });
    },
    openFilter(){
      this.showFilterSection = !this.showFilterSection
    },
    saveFilter(){
      this.fetchPaymentChannels();
      this.showFilterSection = false;
      if(this.countryForFilter.length > 0) {
        this.isFilterApplied = true;
      } else {
        this.isFilterApplied = false;
      }
    },
    clearFilter(){
      this.countryForFilter = []
      this.fetchPaymentChannels();
      this.showFilterSection = false;
      this.isFilterApplied = false;
    },

    merchantSelected() {
      this.fetchPaymentChannels();
    },

    fetchPaymentChannels() {
      let payload = {
        country: this.countryForFilter,
        paymentMethod: "",
        paymentType: "PAYOUT"
      };
      if(this.merchant && this.isMasterMerchant) {
        payload.merchant_uuids = this.merchant.uuid;
      }
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, payload);
    },
    channelConfigLink(data) {
      if(this.merchant) {
        return `/settings/payout-channel/update-config?channelName=${data.key}&&mKey=${this.merchant.uuid}&&mEmail=${this.merchant.email_id}`
      }
      return `/settings/payout-channel/update-config?channelName=${data.key}`;
    },
    getRegionName(countryCode) {
      let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
      return regionNames.of(countryCode);
    }
  },
};
</script>

<template>
  <div>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="">
      <div class="pt-0 position-relative">
          <!-- Payment Channel tab -->
            <div class="position-relative">
              <!-- <div @click="openFilter()" class="my-3 h-50" style="margin-left: 4%;"><FilterBtn :isActive="isFilterApplied" /></div> -->
              <b-btn @click="openFilter()" style="position: absolute;right: 10px; bottom:37px;" class="filter-button" variant="white">
                <img style="padding-right: 10px;" src="@/assets/images/filter.svg" alt="Filter">{{ $t('common.filters') }}
              </b-btn>

              <!-- <div v-if="showFilterSection" class="filter-section">
                <div class="my-3 h-50" style="margin-left: 4%;">
                  <div @click="openFilter()" class="mb-2"><FilterBtn :isActive="isFilterApplied" /></div>
                </div>
                <div class="top-section">
                  <div class="row">
                    <div class="filter_channel">
                      <div class="filter_heading">COUNTRY</div>
                        <div class="checkbox-group"> 
                          <input type="checkbox" id="filter_global" value="GLOBAL" v-model="countryForFilter">
                          <label for="filter_global">Global</label>
                        </div>
                        <div class="checkbox-group" v-for="country in countries" :key="country">  
                          <input type="checkbox" :id="`filter_${country}`" :value="country" v-model="countryForFilter">
                          <label :for="`filter_${country}`">{{ getRegionName(country) }}</label>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="bottom-section float-right">
                  <button @click="clearFilter()" class="mr-2 btn btn-light btn-sm">{{ $t("button.clear") }}</button>
                  <button @click="saveFilter()" class="btn btn-primary btn-sm">
                    {{ $t("button.apply") }}
                  </button>
                </div>
              </div> -->


            <div v-if="showFilterSection" class="filter-section">
              <div class="top-section">
                  <div class="filter_channel">
                    <div class="filter_heading">{{ $t('views.payment_channels.country') }}</div>
                      <div class="filter_channel_section">
                        <div class="checkbox-parent" :class="{ 'active': countryForFilter.indexOf('GLOBAL') > -1 }">
                          <div class="checkbox-group"> 
                            <input type="checkbox" id="filter_global" value="GLOBAL" v-model="countryForFilter">
                            <label for="filter_global">{{ $t('views.payment_channels.global') }}</label>
                          </div>
                        </div>
                        <div class="checkbox-parent" v-for="country in countries" :key="country" :class="{ 'active': countryForFilter.indexOf(country) > -1 }" >
                          <div class="checkbox-group">  
                            <input type="checkbox" :id="`filter_${country}`" :value="country" v-model="countryForFilter">
                            <label :for="`filter_${country}`">{{ getRegionName(country) }}</label>
                          </div>
                        </div>
                      </div>
                  </div>
                  <!-- <div class="filter_method"> 
                      <div class="filter_heading">{{ $t('views.payment_channels.payment_methods') }}</div>
                      <div class="checkbox-group">  
                        <input type="radio" id="filter_wallet" value="WALLET" v-model="paymentMethodForFilter">
                        <label for="filter_wallet">Wallet</label>
                      </div>
                      <div class="checkbox-group">  
                        <input type="radio" id="filter_credit_card" value="INT_CREDIT_CARD" v-model="paymentMethodForFilter">
                        <label for="filter_credit_card">Credit Card</label>
                      </div>
                      <div class="checkbox-group"> 
                        <input type="radio" id="filter_atm_card" value="ATM_CARD" v-model="paymentMethodForFilter">
                        <label for="filter_atm_card">ATM Card</label>
                      </div>
                  </div> -->
              </div>
              <div class="bottom-section float-right">
                <button @click="clearFilter()" class="mr-2 btn font-16px btn-link">Cancel</button>
                <button @click="saveFilter()" class="btn text-chai font-16px btn-link">
                  {{ $t("button.apply") }}
                </button>
              </div>
            </div>

            </div>
            <div class="row justify-content-end mb-4">
              <multiselect
                v-if="isMasterMerchant"
                v-model="merchant"
                :options="merchantListDataFromStore"
                @input="merchantSelected"
                placeholder="Search Merchants"
                track-by="email_id"
                label="email_id"
                class="sm-multiselect col-md-3"
                :searchable="true"
                :multiple="false"
                :taggable="true"
              ></multiselect>
            </div>
            <div class="row" v-if="(isMasterMerchant && merchant) || ! isMasterMerchant">
              <div
                class="col-xl-4 col-md-4 col-lg-4 col-sm-6 dynamic-card-parent"
                v-for="(data, index) in paymentChannelCredsList"
                :key="index"
              >
                <div
                  class="card dynamic-card"
                >
                  <div class="card-body card-body-custom">
                    <router-link
                      :to="channelConfigLink(data)"
                      class=""
                    >
                    <div class="">
                      <div class="img-container">
                        <img
                          :src="`${data.logo}`"
                          alt
                          class="avatar-lg"
                          style="object-fit: contain;position: relative; width: 70px;height: 60px;"
                        />
                        <p style="margin-bottom: 0px;font-size: 18px;font-weight: 400;padding-left: 25px;">{{ data.key }}</p>
                      </div>
                      <p class="description-class" >{{data.description}}</p>
                      <div class="media-body media-body-custom">
                        <p class="mb-0 d-inline-block">
                          {{ data.type }}
                        </p>
                         <img v-if="!data.is_enabled" src="@/assets/images/plus.png" alt />
                        <img v-if="data.is_enabled" src="@/assets/images/green_tick.png" alt />
                      </div>
                    </div>

<!--                     <button
                      href="javascript:void(0);"
                      class="btn btn-primary btn-block"
                      v-if="!data.is_enabled"
                      @click="openPayInModal(data)"
                    >
                      {{ $t("button.setup") }}
                    </button>
                    <button
                      href="javascript:void(0);"
                      class="btn btn-success btn-block"
                      v-if="data.is_enabled"
                      @click="openPayInModal(data)"
                    >
                      {{ $t("button.edit") }}
                    </button> -->
                    <!-- <button href="javascript:void(0);" class="btn btn-block" :class="{ 'btn-primary': data.launched, 'btn-light': !data.launched}">Edit Setup</button> -->
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
input:focus:required:invalid {
  color: green;
}

::v-deep .nav-tabs-custom {
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
}
.card-body-custom{
  padding:0px;
  cursor: pointer;
}
.media-body-custom{
  font-size: 14px;
  font-weight: 325;
  color: rgba(0, 0, 0, 0.50);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.media-body-custom img{
  width: 50px;
  height: 50px;
}
.avatar-background{
    background-image: url("../../../../assets/images/dots.png");
    background-size: 60px;
    background-position-x: 25%;
    background-position-y: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0px;
}
@media (min-width: 1550px) {
    .avatar-background{
      background-position-x: 30%;
  }
}
@media (max-width: 575px) {
    .avatar-background{
      background-position-x: 30%;
  }
}
.dynamic-card{
  margin: auto;
  margin-bottom: 24px;
  box-shadow: none;
  border-radius: 15px;
  padding: 30px;
  border: 1px #DCDAD5 solid;
}
.dynamic-card:hover {
  border: 1px #FC6B2D solid;
  box-shadow: 0px 0px 4px 2px rgba(252, 107, 45, 0.50);
}
::v-deep .nav-tabs > li > a{
  color: #B3AFAF;
}
::v-deep .nav-tabs-custom .nav-item .nav-link.active{
  color:#252B3B;
}
::v-deep .nav-tabs-custom .nav-item .nav-link::after{
  background:#252B3B;
}
@media (max-width: 575px) {
  ::v-deep .page-title-box{
    display: block !important;
    padding-bottom: 10px;
  }
  ::v-deep .page-title-box h4{
    margin-bottom: 5px!important;
  }
}
.filter-section{
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    background: #fff;
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 300px;
    right: 0px;
    border-radius: 15px;
}
/* .top-section{
    border-bottom: 1px solid #F0F0F0;
    padding: 15px;
    padding-left: 35px;
} */
.filter_channel_section{
  height: 350px;
  overflow-y: scroll;
}
.clear_filter{
  color: #BFBFBF;
  display: inline-block;
  margin-left: 15px;
  font-size: 13px;
  cursor: pointer;
}
.bottom-section{
    padding: 15px;
    padding-left: 35px;
}
.filter_heading{
    padding: 25px 25px 15px 25px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.50);
}
.checkbox-group{
  position: relative;
}
.checkbox-group input{
  display: none;
}
.checkbox-group  label {
  cursor: pointer;
  font-size: 13px;
}
.checkbox-group  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #DAD9D9;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  bottom:1px;
}
.checkbox-group input:checked+label:before {
  background-color: #252B3A;
  border: 1px solid #252B3A;
}

.checkbox-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0px 2px 2px 0px;
  transform: rotate(45deg);
}
.filter_method{
    float: right;
    padding-right: 30px;
}
/* .filter_channel {
  height: 40vh;
  overflow-y: scroll;
} */
.img-container{
  display: flex;
  align-items: center;
  justify-content: left;
}
.description-class{
  padding-top: 10px;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.70);
  margin-bottom: 0px;
  padding-bottom: 15px;
  height: 75px;
}
.filter-button {
    border-radius: 12px;
    padding: 12px 16px;
    height: 44px;
    border: 1px solid #DCDAD5;
}
.checkbox-parent{
  padding: 15px 25px 15px 25px;
  border-bottom: 1px rgba(220, 218, 213, 0.30) solid;
}
.checkbox-parent.active{
    background: rgba(220, 218, 213, 0.30);
}
.checkbox-parent label{
  margin-bottom: 0px;
  font-size: 15px;
}
@media (max-width: 1500px) {
  .description-class{
    font-size: 12px;
    height: 75px;
  }
}
</style>