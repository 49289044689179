<script>
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import {
  FETCH_TRANSACTION_ROUTES,
  POST_ROUTING_DETAILS,
  UPDATE_ROUTING_DETAILS,
  DELETE_ROUTING_DETAILS,
  FETCH_ALL_PAYMENT_CHANNEL_LIST,
  CHANGE_DEFAULT_ROUTING_DETAILS
} from "@/state/actions.type";

export default {
  components: { Multiselect },
  data() {
    return {
      form: {
        routing_name: "",
        routing_type: null,
        method_type: null,
        primary: null,
        secondary: null,
      },
      editingRoute: null,
      routingTypes: [{ name: "Failover", value: "failover" }],
      methodTypes: [{ name: "Credit Card", value: "CREDIT_CARD" }],
      primaryChannelList: [],
      secondaryChannelList: [],
      modalShow: false,
      showDeleted: false,
      smartRoutingTableData: [],
      fields: [
        { key: "is_no_code_default", label: 'views.payments.routing.default_no_code' },
        { key: "routing_details.method_type", label: 'views.payments.routing.method_type' },
        { key: "routing_details.primary", label: 'views.payments.routing.primary' },
        { key: "routing_details.secondary", label: 'views.payments.routing.secondary' },
        { key: "routing_name", label: 'views.payments.routing.routing_name' },
        { key: "routing_ref", label: 'views.payments.routing.ref' },
        { key: "routing_type", label: 'views.payments.routing.routing_type' },
        { key: "action", label: 'views.payments.routing.action' },
      ],
    };
  },
  validations: {
    form: {
      routing_name: { required },
      routing_type: { required },
      method_type: { required },
      primary: { required },
      secondary: { required },
    },
  },
  created() {
    this.fetchSmartRouting();
    this.fetchPaymentChannels();
  },
  watch: {
    paymentChannelCredsList() {
      this.primaryPspChanged();
      this.secondaryPspChanged();
    },
  },
  computed: {
    smartRoutingList() {
      return this.$store.state.smartRouting.content;
    },
    paymentChannelCredsList() {
      return this.$store.state.smartRouting.paymentChannels;
    },
  },
  methods: {
    fetchPaymentChannels() {
      this.$store.dispatch(`smartRouting/${FETCH_ALL_PAYMENT_CHANNEL_LIST}`);
    },
    fetchSmartRouting() {
      this.$store.dispatch(`smartRouting/${FETCH_TRANSACTION_ROUTES}`).then(() => this.filterDeletedRows());
    },
    primaryPspChanged(value) {
      this.secondaryChannelList = this.paymentChannelCredsList.filter(channel => !value || value.key !== channel.key);
    },
    secondaryPspChanged(value) {
      this.primaryChannelList = this.paymentChannelCredsList.filter(channel => !value || value.key !== channel.key);
    },
    resetModal() {
      this.form = {
        routing_name: "",
        routing_type: null,
        method_type: null,
        primary: null,
        secondary: null,
      };
      this.$v.$reset();
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (this.form.routing_ref) {
        this.updateRouteDetails();
      } else {
        this.saveRouteDetails();
      }

      this.modalShow = false;
      this.resetModal();
    },

    saveRouteDetails() {
      const formData = {
        routing_name: this.form.routing_name,
        routing_details: {
          routing_type: this.form.routing_type.value,
          method_type: this.form.method_type.value,
          data: {
            primary: this.form.primary.key,
            secondary: this.form.secondary.key,
          },
        },
      };

      this.$store
        .dispatch(`smartRouting/${POST_ROUTING_DETAILS}`, formData)
        .then(() => {
          this.$notify({
            type: "success",
            text: "Routing Added Successfully.",
            closeOnClick: true,
          });
        })
        .catch((error) => {
          console.log(error);
          const errMsg = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: errMsg.message,
            closeOnClick: true,
          });
        });

      setTimeout(() => {
        this.fetchSmartRouting();
      }, 500);
    },

    updateRouteDetails() {
      const formData = {
        routing_name: this.form.routing_name,
        routing_details: {
          routing_type: this.form.routing_type.value,
          method_type: this.form.method_type.value,
          data: {
            primary: this.form.primary.key,
            secondary: this.form.secondary.key,
          },
        },
      };

      this.$store
        .dispatch(`smartRouting/${UPDATE_ROUTING_DETAILS}`, {
          formData: formData,
          routing_ref: this.form.routing_ref,
        })
        .then(() => {
          this.$notify({
            type: "success",
            text: "Routing Added Successfully.",
            closeOnClick: true,
          });
        })
        .catch((error) => {
          console.log(error);
          const errMsg = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: errMsg.message,
            closeOnClick: true,
          });
        });

      setTimeout(() => {
        this.fetchSmartRouting();
      }, 500);
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    editRoute(item) {
      this.modalShow = true;

      this.form.routing_name = item.routing_name;
      this.form.routing_type = this.routingTypes.find(
        (type) => type.value === item.routing_type
      );
      this.form.method_type = this.methodTypes.find(
        (type) => type.value === item.routing_details.method_type
      );
      this.form.primary = this.primaryChannelList.find(
        (channel) => channel.key === item.routing_details.primary
      );
      this.form.secondary = this.secondaryChannelList.find(
        (channel) => channel.key === item.routing_details.secondary
      );

      this.form.routing_ref = item.routing_ref;
      this.editingRoute = item;

      this.primaryPspChanged(this.form.primary);
      this.secondaryPspChanged(this.form.secondary);
    },
    deleteRoute(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              `smartRouting/${DELETE_ROUTING_DETAILS}`,
              item.routing_ref
            )
            .then((response) => {
              this.$notify({
                type: "success",
                text: response.message,
                closeOnClick: true,
              });
              this.fetchSmartRouting();
            })
            .catch((error) => {
              this.$notify({
                type: "error",
                text: error.message,
                closeOnClick: true,
              });
            });
        }
      });
    },
    defalutChanged(item) {
      this.$store
        .dispatch(
          `smartRouting/${CHANGE_DEFAULT_ROUTING_DETAILS}`,
          item.routing_ref
        )
        .then((response) => {
          this.$notify({
            type: "success",
            text: response.message,
            closeOnClick: true,
          });
        })
        .catch((error) => {
          this.$notify({
            type: "error",
            text: error.message,
            closeOnClick: true,
          });
        })
        .finally(() => this.fetchSmartRouting());
    },
    filterDeletedRows() {
      if(this.showDeleted) {
        this.smartRoutingTableData = this.smartRoutingList
      } else {
        this.smartRoutingTableData = this.smartRoutingList.filter(element => !element.is_deleted)
      }
    },
    disableDeleted(item) {
      if(item && item.is_deleted) {
        return 'disable-row';
      }
    }
  },
};
</script>

<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-form-checkbox
        v-model="showDeleted"
        :value="true"
        :unchecked-value="false"
        class="ml-3 my-auto"
        @input="filterDeletedRows"
      >
        {{ $t('views.payments.transactions.show_deleted') }}
      </b-form-checkbox>
      <b-btn
        variant="primary"
        size="sm"
        class="float-right mr-3"
        @click="modalShow = true"
      >
        {{ $t('views.payments.transactions.create_routing') }}
      </b-btn>
    </div>
    <b-modal
      v-model="modalShow"
      :title="$t('views.payments.routing.add_card_type_routing')"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-title="$t('button.save')"
      :cancel-title="$t('button.cancel')"
      cancel-variant="light"
      no-close-on-backdrop
    >
      <form @submit.stop.prevent="handleSubmit">
        <b-form-group id="routing-name-group" :label="$t('views.payments.routing.name')" label-for="routing-name">
            <b-form-input
                id="routing-name"
                name="routing-name"
                v-model="$v.form.routing_name.$model"
                :state="validateState('routing_name')"
                aria-describedby="routing-name-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback
                id="routing-name-live-feedback"
            >This field is required.</b-form-invalid-feedback>
        </b-form-group>
        <div>
          <label for="routing_type">{{$t('views.payments.routing.routing_type')}}</label>
          <multiselect
            name="routing_type"
            v-model="$v.form.routing_type.$model"
            :state="validateState('routing_type')"
            :options="routingTypes"
            track-by="value"
            label="name"
            class="sm-multiselect"
            :searchable="true"
            :multiple="false"
            :taggable="true"
            :preselect-first="true"
          ></multiselect>
          <p
            class="text-danger font-size-12"
            v-if="$v.form.routing_type.$error"
          >
            This field is required.
          </p>
        </div>

        <div>
          <label for="method_type">{{$t('views.payments.routing.method_type')}}</label>
          <multiselect
            name="method_type"
            v-model="$v.form.method_type.$model"
            :state="validateState('method_type')"
            :options="methodTypes"
            track-by="value"
            label="name"
            class="sm-multiselect"
            :searchable="true"
            :multiple="false"
            :taggable="true"
            :preselect-first="true"
          ></multiselect>
          <p class="text-danger font-size-12" v-if="$v.form.method_type.$error">
            This field is required.
          </p>
        </div>

        <div class="mt-3">
          <label for="primary">{{$t('views.payments.routing.primary_channel')}}</label>
          <multiselect
            name="primary"
            v-model="$v.form.primary.$model"
            :state="validateState('primary')"
            :options="primaryChannelList"
            @input="primaryPspChanged"
            track-by="key"
            label="key"
            class="sm-multiselect"
            :searchable="true"
            :multiple="false"
            :taggable="true"
          ></multiselect>
          <p class="text-danger font-size-12" v-if="$v.form.primary.$error">
            This field is required.
          </p>
        </div>

        <div>
          <label for="secondary">{{$t('views.payments.routing.secondary_channel')}}</label>
          <multiselect
            name="secondary"
            v-model="$v.form.secondary.$model"
            :state="validateState('secondary')"
            :options="secondaryChannelList"
            @input="secondaryPspChanged"
            track-by="key"
            label="key"
            class="sm-multiselect"
            :searchable="true"
            :multiple="false"
            :taggable="true"
          ></multiselect>
          <p class="text-danger font-size-12" v-if="$v.form.secondary.$error">
            This field is required.
          </p>
        </div>
      </form>
    </b-modal>

    <div class="table-responsive text-center table-custom">
      <b-table
        class="table-centered mt-3"
        :items="smartRoutingTableData"
        :tbody-tr-class="disableDeleted"
        :fields="fields"
        responsive="sm"
        :bordered="true"
        :small="true"
        sticky-header="500px"
        show-empty
        :empty-text="$t('common.no-records')"
        busy.sync="true"
        hover
      >
      <template v-slot:head()="data">
        <span v-b-tooltip.hover.top :title='$t(data.field.tooltip)'>
          {{ $t(data.label) }}
        </span>
      </template>
        <template #cell(is_no_code_default)="row">
          <b-form-checkbox
            v-if="!row.item.is_deleted"
            v-model="row.value"
            :value="true"
            :unchecked-value="false"
            @change="defalutChanged(row.item)"
          />
        </template>
        <template #cell(action)="row">
          <b-dropdown
            size="sm"
            variant="light"
            right
            v-if="!row.item.is_deleted"
          >
            <template v-slot:button-content>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-three-dots-vertical"
                viewBox="0 0 16 16"
              >
                <path
                  d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                />
              </svg>
            </template>
            <b-dropdown-item-button @click="editRoute(row.item)"
              >Edit</b-dropdown-item-button
            >
            <b-dropdown-item-button @click="deleteRoute(row.item)"
              >Delete</b-dropdown-item-button
            >
          </b-dropdown>
          <b-badge v-else variant="soft-danger" class="font-size-12">Deleted</b-badge>
        </template>
      </b-table>
    </div>
  </div>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
  background: #fff;
  border: none;
  border-bottom: 1px solid #eff2f7;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 13px;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}
::v-deep tr.disable-row > td {
  color: #808080 !important;
  background-color: rgba(0, 0, 0, 0.075) !important;
}
</style>